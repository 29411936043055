import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import ContentfulNextImage from 'src/components/image/ContentfulNextImage';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '../ui/actions';
import type { MotionT } from '../ui/Motion';
import { Motion } from '../ui/Motion';

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <>
        <Text
          color="text"
          fontWeight="semiBold"
          fontSize={['300', '400']}
          textAlign="center"
          extend={() => ({
            lineHeight: 1,
            ':empty': { display: 'none' },
          })}
        >
          {children}
        </Text>
        {Array.isArray(children) && children[0].length > 0 && (
          <Box
            width={['70%', '95%']}
            borderStyle="solid"
            borderBottomWidth={1}
            borderColor="text"
          />
        )}
      </>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const id = node.data.target?.sys.contentType?.sys.id;
      switch (id) {
        case 'action': {
          const { fields } = node.data.target;
          if (!fields || !fields) {
            return null;
          }
          return (
            <Button variant="primary" href={fields.href}>
              {fields.text}
            </Button>
          );
        }
        case 'image': {
          const { fields } = node.data.target;
          if (!fields?.image.fields?.file.url) {
            return null;
          }

          return (
            <Box
              width={['128px', null, 'auto']}
              height={['128px', null, 'auto']}
            >
              <ContentfulNextImage image={fields} />
            </Box>
          );
        }
        case 'motion': {
          const { fields } = node.data.target;
          if (!fields?.motion || !fields.motion.fields) {
            return null;
          }
          const motionProps: MotionT = {
            src: fields.motionUrl || fields.motion.fields.file.url,
            posterSrc: fields.poster?.fields?.file.url,
          };
          return <Motion {...motionProps} />;
        }
        default:
          return null;
      }
    },
  },
};

interface Props {
  document: Document;
}

export function BulletRenderer({ document }: Props): any {
  return documentToReactComponents(
    {
      ...document,
      content: document.content.map((x) => ({
        ...x,

        id: x.data?.target?.sys.contentType?.sys.id,
      })),
    },
    options,
  );
}
