import type { Document } from '@contentful/rich-text-types';
import * as React from 'react';
import type { ImageDecoration } from 'shared/cms/content-type-types';
import type { ImageT as RagnarImageT } from '@mentimeter/ragnar-ui/image';
import { Image as RagnarImage } from '@mentimeter/ragnar-ui/image';
import { Box } from '@mentimeter/ragnar-ui/box';
import { FrameBrowser } from '@mentimeter/ragnar-ui/frame-browser';
import { FramePhone } from '@mentimeter/ragnar-ui/frame-phone';
import { CaptionRenderer } from '../cms/CaptionRenderer';

export interface DecoratedImageT extends RagnarImageT {
  caption?: Document | undefined;
  decoration?: ImageDecoration | undefined;
  enableDefaultSrcFit?: boolean;
}

export const DecoratedImage = ({
  decoration,
  caption,
  src,
  loading = 'lazy',
  enableDefaultSrcFit = true,
  ...rest
}: DecoratedImageT) => {
  switch (decoration) {
    case 'browser':
      const transformedSrc = enableDefaultSrcFit
        ? `${src}?fit=fill&w=1600&h=900`
        : src;
      return (
        <>
          <FrameBrowser>
            <RagnarImage
              src={transformedSrc}
              extend={() => ({
                flexShrink: 0,
              })}
              loading={loading}
              {...rest}
            />
          </FrameBrowser>
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
    case 'phone':
      return (
        <>
          <FramePhone>
            <RagnarImage
              src={src}
              extend={() => ({
                flexShrink: 0,
              })}
              loading={loading}
              {...rest}
            />
          </FramePhone>
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
    case 'round':
      return (
        <>
          <Box
            borderRadius={3}
            overflow="hidden"
            bg="neutralWeakest"
            width="100%"
          >
            <RagnarImage
              src={src}
              extend={() => ({
                flexShrink: 0,
              })}
              loading={loading}
              {...rest}
            />
          </Box>
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
    case 'box':
      return (
        <>
          <Box
            flex="1 1 auto"
            height="100%"
            width="100%"
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundImage={`url(${src})`}
          />
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
    default:
      return (
        <>
          <RagnarImage
            src={src}
            extend={() => ({
              flexShrink: 0,
            })}
            loading={loading}
            {...rest}
          />
          {caption && (
            <Box mt="space2">
              <CaptionRenderer document={caption} />
            </Box>
          )}
        </>
      );
  }
};
