import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Section, Wide } from '../layout';
import { Webinar, WebinarCompact } from '../webinar/Webinar';
import type { WebinarT } from '../webinar/Webinar';

interface WebinarsT {
  webinars?: Array<WebinarT>;
  webinarsFeatured?: Array<WebinarT>;
}

export function Webinars({ webinars, webinarsFeatured }: WebinarsT) {
  return (
    <Section>
      <Wide
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="stretch"
      >
        {webinarsFeatured &&
          webinarsFeatured.map((w) => {
            if (w.livestormUrl) {
              return <Webinar mt="space8" key={`featured-${w.title}`} {...w} />;
            } else {
              return (
                w.date && (
                  <Webinar mt="space8" key={`featured-${w.title}`} {...w} />
                )
              );
            }
          })}
        {webinars &&
          webinars.map((w) => {
            if (w.livestormUrl) {
              return <WebinarCompact mt="space8" key={w.title} {...w} />;
            } else {
              return (
                w.date && <WebinarCompact mt="space8" key={w.title} {...w} />
              );
            }
          })}
        <Box width="31%" />
      </Wide>
    </Section>
  );
}
