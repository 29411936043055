import * as React from 'react';
import type { Document } from '@contentful/rich-text-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Section, Wide, Limit } from '../layout';
import { BodyRenderer } from '../../cms/BodyRenderer';
import { H2, P } from '../typography';
import type { StoryT } from '../Story';
import { Story } from '../Story';
import type { ThemeT } from '../Theme';

export interface StoriesT extends ThemeT {
  body?: Document | undefined;
  heading?: string;
  stories: Array<StoryT>;
  text?: string;
}

export function Stories({ body, heading, stories, text, theme }: StoriesT) {
  return (
    <Section theme={theme}>
      <Wide alignItems="center">
        {(body || heading) && (
          <Limit mb={['space2', 'space8']}>
            {body ? (
              <BodyRenderer document={body} />
            ) : (
              <>
                {heading ? (
                  <H2>{heading}</H2>
                ) : (
                  <H2
                    extend={() => ({
                      display: 'none',
                    })}
                  >
                    Customer stories
                  </H2>
                )}
                {text && <P>{text}</P>}
              </>
            )}
          </Limit>
        )}
        <Box alignItems="stretch">
          <Box mx={['-space2', '-space4', '-space8']}>
            <Box flexDirection="row" flexWrap="wrap" width="100%">
              {stories.map((s) => (
                <Box
                  key={s.title}
                  width={s.video ? [1, null, 2 / 3] : [1, null, 1 / 3]}
                  px={['space2', 'space4', 'space8']}
                  mb="space8"
                >
                  <Story {...s} />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Wide>
    </Section>
  );
}
