import * as React from 'react';
import type { Document } from '@contentful/rich-text-types';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Section, Wide, Limit } from '../layout';
import { H2, P, Bold } from '../typography';
import { Link } from '../actions';
import type { ArticleT } from '../Article';
import { Article } from '../Article';
import { BodyRenderer } from '../../cms/BodyRenderer';
import type { ThemeT } from '../Theme';

export interface ArticlesT extends ThemeT {
  articles: Array<ArticleT>;
  body: Document | undefined;
  heading?: string;
  variant: 'cards' | 'list' | undefined;
  text?: string;
  centerBody: boolean | undefined;
}

export function Articles({
  articles,
  body,
  heading,
  variant,
  text,
  theme,
  centerBody,
}: ArticlesT) {
  return (
    <Section theme={theme}>
      <Wide alignItems="stretch">
        {(body || heading) && (
          <Limit
            alignItems={centerBody ? 'center' : undefined}
            extend={() => ({ textAlign: centerBody ? 'center' : undefined })}
            mx={centerBody ? 'auto' : undefined}
            mb={['space2', 'space8']}
          >
            {body ? (
              <BodyRenderer document={body} />
            ) : (
              <>
                {heading ? (
                  <H2>{heading}</H2>
                ) : (
                  <H2
                    extend={() => ({
                      display: 'none',
                    })}
                  >
                    Browse more resources
                  </H2>
                )}
                {text && <P>{text}</P>}
              </>
            )}
          </Limit>
        )}
        {variant === 'list' ? (
          <Box flexDirection="row" flexWrap="wrap">
            {articles.map(
              (
                { badge, heading: articleHeading, href, text: articleText },
                i,
              ) => (
                <Box
                  mb="space2"
                  width={1 / 2}
                  pl={['space0', i % 2 ? 'space4' : 'space0']}
                  pr={['space0', i % 2 === 0 ? 'space4' : 'space0']}
                  key={href}
                >
                  <Link
                    fontWeight="semiBold"
                    underline={false}
                    href={href ?? ''}
                  >
                    {articleHeading}
                  </Link>
                  <Text>
                    {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
                    <Bold mr={badge ? 1 : 0}>{articleText}</Bold>
                    {badge && badge}
                  </Text>
                </Box>
              ),
            )}
          </Box>
        ) : (
          <Box mx="-space8">
            <Box
              flexDirection={['column', null, 'row']}
              flexWrap="wrap"
              width="100%"
            >
              {articles.map((a) => (
                <Box
                  as="article"
                  width={[1, null, 1 / 3]}
                  mb={[
                    'space8',
                    null,
                    articles.length > 3 ? 'space8' : 'space0',
                  ]}
                  px="space8"
                  key={a.href}
                >
                  <Article {...a} />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Wide>
    </Section>
  );
}
