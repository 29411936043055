import type { DecoratedNextImageT } from 'src/components/image/DecoratedNextImage';
import { DecoratedNextImage } from 'src/components/image/DecoratedNextImage';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { Button } from '../actions';
import { Limit, Section, Wide } from '../layout';
import type { ThemeT } from '../Theme';
import { H2, P } from '../typography';

export interface StatementT extends ThemeT, StatementInnerT {
  heading?: string;
  text?: string;
}

export function Statement({
  layout = 'column',
  heading,
  text,
  theme,
  ...rest
}: StatementT) {
  return (
    <Section theme={theme}>
      <Wide alignItems="center">
        {heading && (
          <Limit
            mb={['space2', 'space8']}
            alignItems="center"
            extend={() => ({
              textAlign: 'center',
            })}
          >
            {heading && <H2>{heading}</H2>}
            {text && <P>{text}</P>}
          </Limit>
        )}
        <StatementInner layout={layout} {...rest} />
      </Wide>
    </Section>
  );
}

export interface StatementInnerT {
  action?: ButtonT | undefined;
  attribution?: string | undefined;
  image?: DecoratedNextImageT | undefined;
  layout?: 'column' | 'row' | undefined;
  statement: string;
}

export const StatementInner = ({
  statement,
  image,
  layout,
  attribution,
  action,
}: StatementInnerT) => (
  <Box
    flexDirection={layout === 'row' ? ['column', null, 'row'] : 'column'}
    alignItems="center"
    justifyContent="center"
    width={1}
  >
    {image && (
      <Box
        width={layout === 'row' ? [1, 2 / 3, 1 / 2] : [1, 2 / 3, '200px']}
        mr={layout === 'row' ? ['space0', null, 'space8'] : 'space0'}
        mb={layout === 'row' ? ['space8', null, 'space0'] : 'space8'}
      >
        <Box
          height="100%"
          width="100%"
          maxHeight={['300px', null, '400px']}
          maxWidth={['300px', null, '400px']}
          ml="auto"
          mr="auto"
        >
          <DecoratedNextImage {...image} />
        </Box>
      </Box>
    )}
    <Box
      alignItems="center"
      px={layout === 'row' ? ['space0', null, null, 'space8'] : 'space0'}
      width={layout === 'row' ? [1, null, 1 / 2] : 1}
    >
      <Text
        textAlign="center"
        fontSize={layout === 'row' ? [4, 5] : [4, null, null, 6]}
        color="text"
        lineHeight="body"
      >
        {statement}
      </Text>
      {attribution && (
        <Text
          color="primary"
          mt="space4"
          textAlign="center"
          fontSize={['100', '125']}
        >
          {attribution}
        </Text>
      )}
      {action && <Button variant="outline" {...action} mt="space8" />}
    </Box>
  </Box>
);
