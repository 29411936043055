import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Ratio } from '@mentimeter/ragnar-ui/ratio';
import { Button, Clickable } from './actions';
import { DecoratedImage } from './DecoratedImage';
import { Limit } from './layout';
import { H3 } from './typography';
import { Video } from './Video';

export interface StoryT {
  image: string;
  title: string;
  logotype?: string;
  slug?: string;
  video?: string | undefined;
  logotypeAlt?: string;
}

export function Story({
  image,
  logotype,
  logotypeAlt,
  slug,
  title,
  video,
}: StoryT) {
  const action = {
    href: slug ? `/stories/${slug}` : '#',
  };

  return (
    <Box
      width="100%"
      extend={() => ({
        ':hover': slug
          ? {
              h3: {
                marginLeft: '5px',
                transition: 'margin-left 0.2s',
              },
            }
          : {},
      })}
    >
      <Box overflow="hidden" mb="space4" width="100%">
        {video ? (
          <Video ratio="46%" url={video} preview={image} title={title} />
        ) : (
          <Clickable width="100%" {...action}>
            <Ratio
              overflow="hidden"
              backgroundImage={`url(${image}?w=800&q=80)`}
              backgroundSize="cover"
              backgroundPosition="center"
            />
          </Clickable>
        )}
      </Box>
      {logotype && (
        <DecoratedImage
          src={logotype}
          alt={logotypeAlt}
          mb="space4"
          height="30px"
        />
      )}
      <Limit>
        <Clickable {...action}>
          <H3>{title}</H3>
        </Clickable>
      </Limit>
      {slug && (
        <Button variant="subtle" href={`/stories/${slug}`}>
          Read more
        </Button>
      )}
    </Box>
  );
}
