import type { Document } from '@contentful/rich-text-types';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { contentfulImageLoader } from 'src/cms/utils/utils';
import type { NextImageProps } from 'src/components/image/NextImage';
import NextImage from 'src/components/image/NextImage';
import type { IPersonFields } from 'types/generated/contentful';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Ratio } from '@mentimeter/ragnar-ui/ratio';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Tooltip } from '@mentimeter/ragnar-ui/tooltip';
import { BodyRenderer } from '../../cms/BodyRenderer';
import { DescriptionRenderer } from '../../cms/DescriptionRenderer';
import { Limit, Section, Wide } from '../layout';
import type { ThemeT } from '../Theme';
import { H2, P } from '../typography';

export interface TeamMemberT extends Omit<IPersonFields, 'image'> {
  id: string;
  image: NextImageProps;
}

export interface TeamT extends ThemeT {
  body?: Document | undefined;
  heading?: string;
  persons: Array<TeamMemberT>;
  text?: string;
  variant?: 'default' | 'detailed' | undefined;
}

const colors = [...designSystemConfig.visualizationColors.fillColors];

export function Team({ body, heading, persons, text, theme, variant }: TeamT) {
  return (
    <Section theme={theme}>
      <Wide alignItems="stretch">
        {(body || heading) && (
          <Limit mb={['space2', 'space8']}>
            {body ? (
              <BodyRenderer document={body} />
            ) : (
              <>
                {heading && <H2>{heading}</H2>}
                {text && <P>{text}</P>}
              </>
            )}
          </Limit>
        )}
        {variant === 'detailed' ? (
          <Box mx={['space0', '-space4', null, '-space8']}>
            <Box flexDirection="row" flexWrap="wrap" width="100%">
              {persons.map(
                ({ id, image, name, lastName, detailedDescription }) => {
                  return (
                    <Box
                      width={[1, 1 / 2, 1 / 3]}
                      key={id}
                      px={['space0', 'space4', null, 'space8']}
                      mb={['space4', 'space8', null, 'space16']}
                      alignItems="flex-start"
                    >
                      <Ratio>
                        <NextImage
                          {...image}
                          style={{ objectFit: 'cover', height: '100%' }}
                          width={400}
                        />
                      </Ratio>
                      <Text
                        fontSize={['125', null, null, '150']}
                        textAlign="center"
                        fontWeight="semiBold"
                        color="text"
                        lineHeight="heading"
                        mt="space4"
                      >
                        {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
                        {name} {lastName}
                      </Text>
                      {detailedDescription && (
                        <Box mt="space4">
                          <DescriptionRenderer document={detailedDescription} />
                        </Box>
                      )}
                    </Box>
                  );
                },
              )}
            </Box>
          </Box>
        ) : (
          <Box mx={['-space2', '-space4', null, '-space8']}>
            <Box flexDirection="row" flexWrap="wrap" width="100%">
              {persons.map(({ description, id, image, name, role }) => {
                const colorIndex = Math.floor(Math.random() * colors.length);
                return (
                  <Tooltip
                    key={id}
                    className="max-w-[320px]"
                    trigger={
                      <Box
                        width={[1 / 3, 1 / 5]}
                        px={['space2', 'space4', null, 'space8']}
                        mb={['space4', 'space8', null, 'space16']}
                        alignItems="center"
                      >
                        <Box
                          borderRadius={3}
                          overflow="hidden"
                          id={id}
                          bg="neutralWeakest"
                          width="100%"
                          mb="space4"
                          extend={() => ({
                            transition: 'all 0.25s ease',
                            transformOrigin: 'center bottom',
                            ':hover': {
                              transform: `scale(1.05)`,
                              background: `${colors[colorIndex]}`,
                            },
                            ':hover > img': {
                              transform: `scale(1.15) translateY(15px)`,
                            },
                          })}
                        >
                          <NextImage
                            {...image}
                            width={200}
                            height={200}
                            quality={100}
                            unoptimized
                            src={contentfulImageLoader({
                              width: 200,
                              format: 'webp',
                              quality: 100,
                              src: image.src,
                            })}
                            style={{
                              transition: 'all .25s ease',
                              transformOrigin: 'center bottom',
                            }}
                          />
                        </Box>
                        <Text
                          fontSize={['100', null, null, '125']}
                          textAlign="center"
                          fontWeight="semiBold"
                          color="text"
                          lineHeight="heading"
                        >
                          {name}
                        </Text>
                        <Text
                          mt="space1"
                          fontSize={['87.5', null, null, '100']}
                          textAlign="center"
                          color="textWeaker"
                          lineHeight="heading"
                        >
                          {role}
                        </Text>
                      </Box>
                    }
                  >
                    {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
                    {description}
                  </Tooltip>
                );
              })}
            </Box>
          </Box>
        )}
      </Wide>
    </Section>
  );
}
