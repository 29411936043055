'use client';
import * as React from 'react';
import type { AlignItemsProperty, Responsive } from '@mentimeter/ragnar-styled';
import type { IBlockHighlightFields } from 'types/generated/contentful';
import { linkActionFields } from 'src/utils/linkActionFields';
import { Box } from '@mentimeter/ragnar-ui/box';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { BodyRenderer } from '../../cms/BodyRenderer';
import { MediaRenderer } from '../../cms/MediaRenderer';
import { Action } from '../actions/Action';
import { Narrow, Section, Wide } from '../layout';
import { Badge } from '../typography';

interface LayoutT {
  layout?: 'right' | 'left' | 'bottom' | 'top';
}

interface VariantT {
  variant?: 'fill' | undefined;
}

function Width({ layout, variant, ...rest }: LayoutT & VariantT & BoxT) {
  if (layout === 'right' || layout === 'left') {
    if (variant === 'fill') {
      return (
        <Box
          alignItems="center"
          width="100%"
          maxWidth={['none', 'none', 'none', 'none', '1200px']}
          flexDirection={[
            'column-reverse',
            null,
            layout === 'left' ? 'row' : 'row-reverse',
          ]}
          {...rest}
        />
      );
    }
    return (
      <Wide
        alignItems="center"
        flexDirection={[
          'column',
          null,
          layout === 'left' ? 'row' : 'row-reverse',
        ]}
        {...rest}
      />
    );
  }

  if (variant === 'fill') {
    return (
      <Box
        flexDirection={layout === 'bottom' ? 'column-reverse' : 'column'}
        alignItems="center"
        {...rest}
      />
    );
  }

  return (
    <Narrow
      flexDirection={layout === 'bottom' ? 'column-reverse' : 'column'}
      alignItems="center"
      {...rest}
    />
  );
}

const actionalignItemsByLayout: {
  [key: string]: Responsive<AlignItemsProperty>;
} = {
  right: 'flex-start',
  left: 'flex-start',
  bottom: 'center',
  top: 'center',
};

export function Highlight({
  action,
  badge,
  body,
  layout = 'right',
  variant,
  media,
  mood,
  imageVerticalAlignment,
  imageMaxWidth,
  useMinimumHeight,
  ...rest
}: Omit<IBlockHighlightFields, 'title'>) {
  const FLEX_ALIGN = {
    center: 'center',
    top: 'flex-start',
    bottom: 'flex-end',
  };

  const actionProps = linkActionFields(action?.fields);
  const alignImage = imageVerticalAlignment
    ? FLEX_ALIGN[imageVerticalAlignment]
    : 'center';
  const theme = mood?.fields ? { colors: mood.fields.colors } : undefined;

  return (
    <Section theme={theme} {...rest}>
      <Width layout={layout} variant={variant}>
        <Box
          width={layout === 'top' || layout === 'bottom' ? 1 : [1, null, 1 / 2]}
          height="100%"
          flex="1 1 auto"
          minHeight={
            variant === 'fill' && useMinimumHeight ? [300, 400, 550] : 'none'
          }
          alignItems={alignImage as AlignItemsProperty}
          extend={() => ({ alignSelf: alignImage })}
          maxWidth={imageMaxWidth}
          mb={['space8']}
        >
          {media && <MediaRenderer document={media} />}
        </Box>
        <Box
          width={layout === 'top' || layout === 'bottom' ? 1 : [1, null, 1 / 2]}
          maxWidth={
            (layout === 'top' || layout === 'bottom') && variant === 'fill'
              ? '800px'
              : undefined
          }
          pb={
            variant === 'fill'
              ? 'space8'
              : layout === 'top' || layout === 'bottom'
                ? 'space8'
                : 'space0'
          }
          pt={
            variant === 'fill'
              ? 'space8'
              : layout === 'top' || layout === 'bottom'
                ? 'space8'
                : ['space2', null, 'space0']
          }
          pl={
            variant === 'fill'
              ? ['space8', null, 'space16', 'space16', 'space8']
              : layout === 'left'
                ? ['space0', null, 'space16']
                : 'space0'
          }
          pr={
            variant === 'fill'
              ? ['space8', null, 'space16', 'space16', 'space8']
              : layout === 'right'
                ? ['space0', null, 'space16']
                : 'space0'
          }
          flex="1 1 auto"
          alignItems={
            layout === 'top' || layout === 'bottom' ? 'center' : 'flex-start'
          }
          extend={() => ({
            textAlign:
              layout === 'top' || layout === 'bottom' ? 'center' : 'left',
          })}
        >
          {badge && <Badge>{badge?.fields.text}</Badge>}
          {body && <BodyRenderer document={body} />}
          {action && (
            <Box
              width="100%"
              mt={['space6', 'space8']}
              alignItems={
                layout === 'left' || layout === 'right'
                  ? 'flex-start'
                  : 'center'
              }
            >
              <Action
                variant="outline"
                {...actionProps}
                alignItems={actionalignItemsByLayout[layout]}
              />
            </Box>
          )}
        </Box>
      </Width>
    </Section>
  );
}
